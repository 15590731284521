import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { useAppDispatch } from '../../app/hooks';
import { Button, Flex, FormInputError, TextArea } from '../../components/ui';
import { FormControl } from '../../components/ui/forms/Forms';
import { hasEnoughWords, trimFormValues } from '../../utils/helpers';
import { Footer, GreenShell } from '../../components/GreenShell';
import { generateSiteIdentifier } from '../../utils/url';
import { SiteOnboardLocationState } from './model';
import { requestSiteAccess } from './siteOnboardSlice';
import { AuthPageLayout } from '../../pages';
import { SecondaryButton } from '../../pages/auth/AuthPageLayout';

export type RequestAccessFormData = {
  message: string;
};

const initialValues: RequestAccessFormData = {
  message: '',
};

const RequestSiteAccess: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const dispatch = useAppDispatch();

  const state = location.state as SiteOnboardLocationState;

  const mapUrl = () => {
    if (state.action === 'request') {
      return `/map/site/${generateSiteIdentifier(
        state.site.siteId,
        state.site.siteName
      )}`;
    }

    return `/map`;
  };

  useEffect(() => {
    if (state.action === 'request') {
      if (!state?.site) history.goBack();
    }
  }, [history, state]);

  const validateForm = ({ message }: RequestAccessFormData) => {
    let errors: FormikErrors<RequestAccessFormData> = {};

    if (!message) {
      errors.message = 'Please provide a message to the Soil Maker.';
    }
    if (!hasEnoughWords(message, 3)) {
      errors.message = 'Please provide a longer message to the Soil Maker.';
    }
    return errors;
  };

  const submitForm = async (values: RequestAccessFormData) => {
    values = trimFormValues(values);

    const { message } = values;

    if (state.action === 'request') {
      const { siteId, siteName } = state.site;

      const result = await dispatch(
        requestSiteAccess({ siteId, message })
      ).unwrap();

      if (
        !result.requestSiteAccess &&
        result.requestSiteAccess.errors.length > 0
      ) {
        toast.error(result.requestSiteAccess.errors[0]);
      } else {
        toast.success(result.requestSiteAccess.message);
        history.push(`/map/site/${generateSiteIdentifier(siteId, siteName)}`);
      }
    }
  };

  const handleCancel = () => {
    if (state.action === 'request') {
      const { siteId, siteName } = state.site;
      history.push(`/map/site/${generateSiteIdentifier(siteId, siteName)}`);
    }
  };

  return (
    <AuthPageLayout onClose={() => history.push(mapUrl())}>
      <TextWrapper>
        <h2>Request to join soil site</h2>
        <p>You need the approval of the Soil Maker to join this soil site.</p>
        <p>Let the Soil Maker know why you want to join.</p>
      </TextWrapper>

      <Formik
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={submitForm}
        validateOnMount
      >
        {({ isSubmitting }) => {
          return (
            <FormContainer>
              <Form>
                <FormControl>
                  <Field
                    as={TextArea}
                    name="message"
                    placeholder="Write message here..."
                    resize="vertical"
                  />
                  <ErrorMessage name="message" component={FormInputError} />
                </FormControl>

                <Flex
                  direction="column"
                  gap="1rem"
                  style={{ marginTop: '1rem' }}
                >
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    SEND REQUEST
                  </Button>

                  <SecondaryButton onClick={handleCancel}>
                    CANCEL
                  </SecondaryButton>
                </Flex>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </AuthPageLayout>
  );
};

export default withRouter(RequestSiteAccess);

const FormContainer = styled.div`
  h4 {
    margin: 0;
    color: #616161;
    font-weight: bold;
  }

  textarea {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  text-align: center;
`;
