import styled from 'styled-components';
import { PageHeader } from '../../components/ui/PageHeader';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapsible, ErrorComponent, Flex, Loader } from '../../components/ui';
import { gql, useQuery } from '@apollo/client';
import { SiteListItem } from '../site/model';
import { generateSiteIdentifier } from '../../utils/url';
import { ToggleSwitch } from '../../components/ui/ToggleSwitch';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { isAdmin } from '../auth/utils';
import { SiteCard } from './SiteCard';
import { Placeholder } from './Placeholder';
import { MyAccessRequests } from './MyAccessRequests';
import { getUserDisplayName, pluralize } from '../../utils/helpers';
import { useAuthUser } from '../auth/useAuthUser';
import { Impact } from './Impact';
import { getSiteOwner, isSoilMaker } from '../../utils/site';

const GET_MY_SOIL_SITES = gql`
  query {
    mySoilSites {
      id
      name
      picture
      active
      dropOffs
      makers {
        userId
        firstName
        lastName
        role {
          id
          name
        }
      }
      pendingAccessRequests
    }
  }
`;

export const SiteList = () => {
  const authUser = useAuthUser();

  const { loading, error, data } = useQuery(GET_MY_SOIL_SITES, {
    fetchPolicy: 'network-only',
  });

  const [hideDeactivatedSite, setHideDeactivatedSites] = useLocalStorage(
    'hideDeactivatedSites',
    false
  );

  const showDeactivatedSitesToggle = true;

  if (loading) return <Loader />;
  if (error) return <ErrorComponent error={error} />;

  const { mySoilSites }: { mySoilSites: SiteListItem[] } = data;

  const iMakeAt = mySoilSites
    .filter((site) => isSoilMaker(site.makers, authUser!.id))
    .filter((site) => (hideDeactivatedSite ? site.active : site))
    // show sites that have most access requests first
    .sort((a, b) =>
      a.pendingAccessRequests > b.pendingAccessRequests ? 1 : -1
    )
    .sort((a, b) => (!a.active && b.active ? 1 : -1));

  const iSupportAt = mySoilSites
    .filter((site) => !isSoilMaker(site.makers, authUser!.id))
    .filter((site) => (hideDeactivatedSite ? site.active : site))
    .sort((a, b) => (!a.active && b.active ? 1 : -1));

  const iAmMaker = iMakeAt.length > 0;
  const iAmSupporter = iSupportAt.length > 0;

  const MakerCollapsible = () => {
    const siteCount = iMakeAt.length;

    const makeSectionHeader =
      siteCount > 0
        ? `You are a Soil Maker at ${siteCount} soil ${pluralize(
            siteCount,
            'site'
          )}`
        : 'You are not yet a Soil Maker';

    return (
      <Collapsible label={makeSectionHeader}>
        <SiteListContainer>
          {iMakeAt.map((site: any) => (
            <SiteCard
              {...generateSiteCardProps(site, authUser.id)}
              displayEditButton
            />
          ))}

          <Placeholder type="Maker" first={siteCount === 0} />
        </SiteListContainer>
      </Collapsible>
    );
  };

  const SupporterCollapsible = () => {
    const siteCount = iSupportAt.length;

    const supportSectionHeader =
      siteCount > 0
        ? `You are a Soil Supporter at ${siteCount} soil ${pluralize(
            siteCount,
            'site'
          )}`
        : 'You are not yet a Soil Supporter';

    return (
      <Collapsible label={supportSectionHeader}>
        <SiteListContainer>
          {iSupportAt.map((site: SiteListItem) => (
            <SiteCard {...generateSiteCardProps(site, authUser.id)} />
          ))}
          {siteCount < 2 && (
            <Placeholder type="Supporter" first={siteCount === 0} />
          )}
        </SiteListContainer>
      </Collapsible>
    );
  };

  return (
    <>
      <StyledPageHeader
        pageTitle="My soil sites"
        right={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Link to="create-soil-site">
              <FontAwesomeIcon icon="plus" /> Create soil site
            </Link>
            {showDeactivatedSitesToggle && (
              <ToggleSwitch
                on={hideDeactivatedSite}
                onToggle={(e) => setHideDeactivatedSites(e.target.checked)}
                label="Hide deactivated soil sites"
              />
            )}
          </div>
        }
      />
      <Container>
        <Impact iAmMaker={iAmMaker} iAmSupporter={iAmSupporter} />

        <Flex>
          {/* Change the order of lists based on whether user is soil maker or soil supporter only */}
          <div style={{ flex: '2' }}>
            {iMakeAt.length > 0 ? MakerCollapsible() : SupporterCollapsible()}
            <div style={{ marginTop: '2rem' }} />
            {iMakeAt.length > 0 ? SupporterCollapsible() : MakerCollapsible()}
            <div style={{ marginTop: '2rem' }} />
            <MyAccessRequests />
          </div>
        </Flex>
      </Container>
    </>
  );
};

function generateSiteCardProps(site: SiteListItem, currentUserId: number) {
  const {
    id,
    name,
    picture,
    makers,
    active,
    pendingAccessRequests,
    dropOffs,
  } = site;

  const isMaker = isSoilMaker(makers, currentUserId);
  const soilMaker = getSiteOwner(makers);
  return {
    key: id,
    isSoilMaker: isMaker,
    picture,
    active,
    disabled: !active && !isSoilMaker,
    siteName: name,
    participants: 0,
    soilMaker: getUserDisplayName(soilMaker.firstName, soilMaker.lastName),
    pendingAccessRequests,
    dropOffs,
    siteUrl: `my-soil-sites/${generateSiteIdentifier(id, name)}`,
  };
}

const Container = styled.div`
  padding: 1rem;
  height: 100%;
  overflow: auto;

  .collapsible-header {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const StyledPageHeader = styled(PageHeader)`
  position: relative;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none !important;
  }
`;

const SiteListContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    > div {
      width: 100%;
    }
  }
`;
