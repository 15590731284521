import { useState } from 'react';
import { toast } from 'react-toastify';

import { Modal, Button } from '../../../components/ui';
import { useSubmitting } from '../../../hooks/useSubmitting';
import { useDropOff } from './useDropOff';
import { WeightUnit, getWeightUnit } from '../../../utils/formatting';
import { DropOffForm } from './DropOffForm';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { EditDropOffPayload } from './model';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dropOff: EditDropOffPayload;
};

export const EditDropOffModal: React.FC<Props> = ({
  isOpen,
  onClose,
  dropOff,
}) => {
  const {
    updateDropOff,
    convertWeightBeforeSaving,
    convertWeightToDisplay,
  } = useDropOff();

  const [comment, setComment] = useState(dropOff.comment || '');
  const [weight, setWeight] = useState(
    dropOff.weight
      ? convertWeightToDisplay(dropOff.weight, getWeightUnit().unit).toString()
      : ''
  );
  const [weightUnit, setWeightUnit] = useLocalStorage<{ unit: WeightUnit }>(
    'weight-unit',
    getWeightUnit()
  );

  const handleUpdateDropOff = async () => {
    try {
      await updateDropOff({
        comment,
        // convert to Pounds if weight is in kg
        weight: convertWeightBeforeSaving(weight, weightUnit.unit),
        siteId: dropOff.siteId,
        sitePostId: dropOff.sitePostId,
      });

      toast.success('Your drop-off has been updated.');
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const { handleSubmit, isSubmitting } = useSubmitting(handleUpdateDropOff);

  return (
    <Modal
      header="Edit drop-off"
      isOpen={isOpen}
      onClose={onClose}
      actionButton={
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          UPDATE DROP-OFF
        </Button>
      }
    >
      <DropOffForm
        useDropOffAs={false}
        useWeight={true}
        weight={weight}
        setWeight={setWeight}
        weightUnit={{ unit: weightUnit?.unit }}
        setWeightUnit={setWeightUnit}
        comment={comment}
        setComment={setComment}
      />
    </Modal>
  );
};
