import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Meta } from '../../../components/Meta';
import { BouncingArrow } from '../../../components/ui/BouncingArrow';
import { Button, Flex } from '../../../components/ui';
import { Donorbox } from '../Donorbox';
import { useScrollIntoView } from '../../../hooks/useScrollIntoView';
import { SponsorList } from './SponsorList';
import { StripeDonation } from '../stripe/StripeDonation';

export default function Sponsor() {
  const { ref, scrollIntoView } = useScrollIntoView();

  return (
    <PageContainer>
      <Meta>
        <title>Soil Sponsors | MakeSoil</title>
      </Meta>
      <Hero>
        <Flex gap="1rem" align="center" justify="center">
          <FontAwesomeIcon className="icon" icon="heart" size="3x" />
          <h1>Become a Soil Sponsor</h1>
        </Flex>

        <TextContainer>
          <p>
            For every <strong>$5K donated</strong>, we can help{' '}
            <strong>500 people</strong> to start composting their food waste,
            creating enough soil to support{' '}
            <strong>5 new local food gardens</strong>.
          </p>
        </TextContainer>

        <div style={{ marginTop: '1rem' }}>
          <Button variant="secondary" onClick={() => scrollIntoView()}>
            BECOME A SOIL SPONSOR
          </Button>

          <Bounce>
            <p>
              Donations to MakeSoil are tax-deductible for US citizens &
              companies.
            </p>

            <BouncingArrow
              icon="arrow-alt-circle-down"
              size="3x"
              style={{ marginTop: '2rem' }}
            />
          </Bounce>
        </div>
      </Hero>

      <Sponsors>
        <h2>Current Soil Sponsors</h2>
        <SponsorList />
      </Sponsors>

      <BecomeSponsor ref={ref}>
        <h2>Become a Soil Sponsor</h2>

        <div style={{ lineHeight: 1.5 }}>
          <p>
            Levels of support range from <strong>$5K to $500K</strong> (the
            largest single donation so far).
          </p>
          <p>
            Soil Sponsors receive personal updates, and help to guide the
            MakeSoil movement.
          </p>
          <p>
            To become a Soil Sponsor, please email{' '}
            <strong>donate@makesoil.org</strong>
          </p>
        </div>

        <div style={{ marginTop: '1rem', fontStyle: 'italic' }}>
          <p style={{ fontSize: '1rem' }}>
            Your support can also remain anonymous if you prefer.
          </p>
        </div>

        <DonationsContainer>
          <p style={{ marginBottom: '1.5rem' }}>
            If you wish to make a donation <strong>below $5K</strong>, please do
            so here:
          </p>

          <StripeDonation />

          <h3>Donate via Card, PayPal or Bank Transfer</h3>

          <Donorbox donorboxUrl="https://donorbox.org/embed/makesoil" />
        </DonationsContainer>
      </BecomeSponsor>
    </PageContainer>
  );
}

const Sponsors = styled.section`
  padding: 2rem 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 3rem 2rem;
  }

  text-align: center;
`;

const PageContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Hero = styled.section`
  position: relative;
  // min-height: calc(60vh - 61px);
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 3rem 2rem;
  }

  img {
    height: 64px;
  }

  background: url('/images/map.png') center no-repeat;
  background-size: cover;
  background-color: #5ab474;

  text-align: center;
  color: #fff;

  h1 {
    margin: 0;
    font-size: 2.75rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 300;
  }

  p {
    font-size: 1.25rem;
    font-weight: 300;

    max-width: 90vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    p {
      max-width: 50vw;
    }
  }

  .icon {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: block;
    }
  }
`;

const BecomeSponsor = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 2rem 5rem;
  }

  background-color: #5ab474;
  color: #fff;
  text-align: center;

  p {
    font-size: 1.25rem;
    font-weight: 300;
    margin: 0.75rem;
  }
`;

const Bounce = styled.div`
  margin-top: 2.5rem;
`;

const TextContainer = styled.div`
  line-height: 1.5;

  margin-top: 0;
  max-width: 90vw;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 2rem;
    max-width: 46vw;
  }
`;

const DonationsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    iframe {
      padding-left: 2.5rem;
    }
  }
`;
