import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, RefObject, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import withForwardedRef from '../../../components/withForwardedRef';
import { TextAreaExpandable } from '../../../components/ui';
import { SiteMember } from '../model';
import { Attachment, PostInputData, PostType } from './model';
import { MessageAttachmentPicker } from './MessageAttachmentPicker';
import { FilestackUploadResponse } from '../../../components/ui/FilePicker';
import { MessageAttachmentPreview } from './MessageAttachmentPreview';
import { isMobile } from '../../../utils/helpers';
import { isSoilMaker } from '../../../utils/site';
import { useSoilSiteContext } from '../SoilSiteContext';
import { useAuthUser } from '../../auth/useAuthUser';
import { useMessageBoard } from './useMessageBoard';

type UserTag = Pick<
  SiteMember,
  'userId' | 'firstName' | 'lastName' | 'picture'
>;

type MessageInputProps = {
  users: UserTag[];
  onPostMessage: (message: PostInputData) => Promise<void>;
  initialPost?: PostInputData;
  forwardedRef?: RefObject<HTMLTextAreaElement>;
  style?: React.CSSProperties;
  autoFocus?: boolean;
  children?: React.ReactNode;
};

const PostInput: React.FC<MessageInputProps> = ({
  initialPost = {
    text: '',
    pinned: false,
    attachments: [],
    type: PostType.MESSAGE,
  },
  forwardedRef,
  onPostMessage,
  users = [],
  style,
  autoFocus = false,
  children,
}) => {
  const { soilSite } = useSoilSiteContext();
  const authUser = useAuthUser();

  const { deleteAttachment, storeUploadedAttachments } = useMessageBoard();

  const textareaRef = forwardedRef || createRef<HTMLTextAreaElement>();
  const statusRef = useRef(null);

  const [message, setMessage] = useState<PostInputData>(() => initialPost);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [filePickerOpen, setFilePickerOpen] = useState(false);

  const isMaker = isSoilMaker(soilSite.members, authUser.id);
  const attachmentsEnabled = true;

  /**
   * Drop off can be posted regardless of text length
   * Message needs at least 3 characters OR an attachment
   */
  const canPostMessage =
    (message.type === PostType.DROPOFF && !isSubmitting) ||
    ((message.text.length >= 1 || message.attachments.length > 0) &&
      !isSubmitting);

  const shouldAutoFocus =
    (autoFocus && !isMobile()) || (isMobile() && !!message.text.length);

  const handleChange = (e) => {
    const value: string = e.target.value;
    setMessage((prev) => ({ ...prev, text: value }));
  };

  const postMessage = async () => {
    // text is required only if there are no attachments
    if (!canPostMessage) return;

    setIsSubmitting(true);

    let trimmedPost = {
      ...message,
      text: message.text.trim(),
      type: message.type || PostType.MESSAGE,
    };
    await onPostMessage(trimmedPost);

    // clear the input if this is not the update
    if (!initialPost.text) {
      setIsSubmitting(false);
      setMessage(initialPost);
    }
  };

  const handleFileUploadSuccess = (response: FilestackUploadResponse) => {
    const attachments = response.filesUploaded.map((file) => ({
      fileType: file.mimetype,
      fileName: file.filename,
      url: file.url,
      handle: file.handle,
      postId: message.id,
    }));
    setMessage((message) => ({
      ...message,
      attachments: [...message.attachments, ...attachments],
    }));
    // store the uploaded attachments so we
    // can track those that need to be removed if user
    // does not post a message
    storeUploadedAttachments([...attachments]);
  };

  const handleRemoveAttachment = async (attachment: Attachment) => {
    let payload = {
      handle: attachment.handle,
    };
    // if no messageId that means it's a new message (not in DB yet)
    if (message.id) {
      Object.assign(payload, { messageId: message.id });
    }

    await deleteAttachment(payload);

    // update the UI
    setMessage((message) => ({
      ...message,
      attachments: message.attachments.filter(
        (a) => a.handle !== attachment.handle
      ),
    }));
  };

  // make sure that when focused the cursor is at the end of the text
  const handleFocus = (e) => {
    const { value } = e.target;
    if (value.length) {
      e.target.setSelectionRange(value.length, value.length);
    }
  };

  const placeholder = isMaker
    ? "How's your soil site doing? Share news with everyone here."
    : 'Have a question or comment? Post here so everyone learns together.';

  return (
    <MessageInputContainer style={style}>
      <MessageAttachmentPicker
        open={filePickerOpen}
        options={{
          storeTo: {
            path: `/site/${soilSite.id}/messageboard/`,
          },
        }}
        onSuccess={handleFileUploadSuccess}
        onClose={() => setFilePickerOpen(false)}
      />
      <TextAreaExpandable
        value={message.text}
        onChange={handleChange}
        placeholder={placeholder}
        forwardedRef={textareaRef}
        autoFocus={shouldAutoFocus}
        style={{ resize: 'vertical' }}
        rows={2}
        onFocus={handleFocus}
      />

      <MessageAttachmentPreview
        attachments={message.attachments}
        onRemoveAttachment={handleRemoveAttachment}
      />

      <MessageInputControlBar>
        <StatusIndicator ref={statusRef} />
        <InputControls>
          {/* <InputControl
            onClick={() => pinMessage()}
            active={message.pinned}
            title="Pin Message"
          >
            <FontAwesomeIcon icon="thumbtack" />
          </InputControl>
          <InputControl onClick={() => takePhoto} title="Emoticons">
            <FontAwesomeIcon icon="smile" />
          </InputControl>
          <InputControl
            onClick={() => {
              setMessage((prev) => ({ ...prev, text: "@" }));
              toggleTagSelector();
            }}
            title="Mention User"
          >
            <FontAwesomeIcon icon="at" />
          </InputControl> */}
          {attachmentsEnabled && (
            <InputControl
              onClick={() => setFilePickerOpen(true)}
              title="Attach Image(s)"
            >
              <FontAwesomeIcon icon="images" />
            </InputControl>
          )}
          {children}
          <InputControl
            onClick={postMessage}
            disabled={!canPostMessage}
            title="Post"
          >
            {isSubmitting ? (
              <FontAwesomeIcon icon="spinner" spin />
            ) : (
              <FontAwesomeIcon icon="paper-plane" />
            )}
          </InputControl>
        </InputControls>
      </MessageInputControlBar>
    </MessageInputContainer>
  );
};

//#region styled components

export const MessageInputContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e6e6e6;

  textarea {
    padding: 0.5rem;
    display: block;
    min-width: 100%;
    border-radius: 4px 4px 0 0;
    border: none;
    background: #fff;
    font-family: inherit;
    font-size: 0.925rem;

    ::placeholder {
      font-style: normal;
      color: #a7a7a7;
    }

    color: #777;

    outline: none;
  }
`;

export const MessageInputControlBar = styled.div`
  padding: 0.25rem;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e6e6e6;
  background-color: #f4f4f4;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StatusIndicator = styled.div`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const InputControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 0;
  }
`;

export const InputControl = styled.button<{ active?: boolean }>`
  background: none;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;

  height: 2rem;
  width: 2.5rem;
  font-size: 1.125rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 2rem;
    width: 2rem;
    font-size: 1rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  &[disabled] {
    svg {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
    cursor: auto;

    &:hover {
      background: none;
    }
  }

  &:hover {
    background: #dadada;
  }

  ${({ active }) =>
    active &&
    css`
      background: #dadada;
      border: 1px solid #c1c1c1;
    `}
`;

//#endregion

export default withForwardedRef(PostInput);
