import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, Field, FormikErrors, ErrorMessage } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Input,
  FormInputError,
  Flex,
  Checkbox,
  Revealer,
  TextArea,
  Button,
} from '../../../components/ui';
import { FilestackUploadResponse } from '../../../components/ui/FilePicker';
import { AddressField } from '../../../components/ui/forms/AddressField';
import { Tooltip } from '../../../components/ui/Tooltip';

import {
  validationMessages,
  scrollToFormError,
} from '../../../utils/formValidation';
import { removeOldFilestackImage } from '../../filestack/utils';
import { MaterialsPicker } from '../components/MaterialsPicker';
import { SiteImage } from '../components/SiteImage';
import { Site } from '../model';
import {
  useCurrentSiteUser,
  useSiteManager,
  useSoilSiteContext,
} from '../hooks';

type SiteEditProps = {
  onCancelEditMode: () => void;
  onDeactivateSite: () => void;
};

export const SiteEdit: React.FC<SiteEditProps> = ({
  onCancelEditMode,
  onDeactivateSite,
}) => {
  const { soilSite } = useSoilSiteContext();
  const { permissions } = useCurrentSiteUser(soilSite);
  const { updateSoilSite, updateSoilSiteImage } = useSiteManager(soilSite.id);

  const [picture, setPicture] = useState<string>(() => soilSite.picture);

  const handleFileUpload = async (response: FilestackUploadResponse) => {
    const [upload] = response.filesUploaded;
    // update the DB now (user might cancel the form edit and new file would stay uploaded)
    updateSoilSiteImage(upload.url);
    setPicture((currentPicture) => {
      // we need to remove the current/old image from filestack
      removeOldFilestackImage(currentPicture);
      return upload.url;
    });
  };

  const updateSite = async (values: Site, { setSubmitting }) => {
    setSubmitting(true);

    const { location } = values;

    const siteData = {
      ...values,
      location: { lat: location.lat, lng: location.lng },
      picture,
    };

    await updateSoilSite(siteData);
    onCancelEditMode();
    setSubmitting(false);
  };

  const validateForm = (values: Site) => {
    const isTruthy = (element) => Boolean(element);
    let errors: FormikErrors<Site> = {};

    if (!values.name) {
      errors.name = validationMessages.soilSite.name;
    }
    if (!values.address) {
      errors.address = validationMessages.soilSite.address;
    }
    if (
      !values.access ||
      values.access.split(' ').filter(isTruthy).length < 3
    ) {
      errors.access = validationMessages.soilSite.access;
    }
    if (
      !values.dropOff ||
      values.dropOff.split(' ').filter(isTruthy).length < 3
    ) {
      errors.dropOff = validationMessages.soilSite.dropOff;
    }
    if (!values.hours) {
      errors.hours = validationMessages.soilSite.hours;
    }
    if (
      !values.description ||
      values.description.split(' ').filter(isTruthy).length < 3
    ) {
      errors.description = validationMessages.soilSite.description;
    }

    return errors;
  };

  return (
    <SiteEditContainer>
      <SiteImage
        image={picture}
        siteId={soilSite.id}
        showTrigger
        onFileUploaded={handleFileUpload}
      />
      <FormContainer>
        <Formik
          initialValues={soilSite}
          onSubmit={updateSite}
          validate={validateForm}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => {
            if (isSubmitting) {
              scrollToFormError(errors);
            }

            return (
              <Form
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    return false;
                  }
                }}
              >
                <Sticky style={{ backgroundColor: '#f4f4f4', top: '-1rem' }}>
                  <FormButtons
                    onCancel={onCancelEditMode}
                    isSubmitting={isSubmitting}
                  />
                </Sticky>

                <FormControlHeader>
                  <h3>Soil Site Name:</h3>
                </FormControlHeader>
                <Field
                  name="name"
                  type="text"
                  placeholder="Your soil site's name"
                  as={Input}
                />

                <ErrorMessage name="name" component={FormInputError} />

                <FormControlHeader justify="space-between" align="center">
                  <h3>Soil Site Privacy:</h3>
                </FormControlHeader>

                <Flex gap="0.5rem" align="center">
                  <Field
                    as={Checkbox}
                    name="isUnlisted"
                    id="isUnlisted"
                    value={values.isUnlisted}
                    checked={!values.isUnlisted}
                    onChange={(e) => {
                      setFieldValue('isUnlisted', !e.target.checked);
                      if (!e.target.checked) {
                        setFieldValue('requiresApproval', true);
                      }
                    }}
                  />
                  <Flex
                    justify="space-between"
                    align="center"
                    style={{ flex: 1 }}
                  >
                    <label htmlFor="isUnlisted" style={{ cursor: 'pointer' }}>
                      <small>Show soil site on map</small>
                    </label>
                    <Tooltip>
                      {values.isUnlisted
                        ? 'Your soil site will be unlisted.'
                        : 'Uncheck to make your soil site unlisted.'}
                    </Tooltip>
                  </Flex>
                </Flex>

                <Revealer isOpen={!values.isUnlisted}>
                  <Flex
                    gap="0.5rem"
                    align="center"
                    style={{ marginTop: '0.75rem' }}
                  >
                    <Field
                      as={Checkbox}
                      name="obfuscate"
                      id="obfuscate"
                      value={values.obfuscate}
                      checked={!values.obfuscate}
                      onChange={(e) => {
                        setFieldValue('obfuscate', !e.target.checked);
                        if (!e.target.checked) {
                          setFieldValue('requiresApproval', true);
                        }
                      }}
                    />
                    <Flex
                      justify="space-between"
                      align="center"
                      style={{ flex: 1 }}
                    >
                      <label htmlFor="obfuscate" style={{ cursor: 'pointer' }}>
                        <small>Show exact location</small>
                      </label>
                      <Tooltip>
                        {values.obfuscate
                          ? 'Your soil site will be given a slightly random location.'
                          : 'Uncheck to give your soil site a slightly random location.'}
                      </Tooltip>
                    </Flex>
                  </Flex>

                  <Flex
                    gap="0.5rem"
                    align="center"
                    style={{ marginTop: '0.75rem' }}
                  >
                    <Field
                      as={Checkbox}
                      name="requiresApproval"
                      id="requiresApproval"
                      value={values.requiresApproval}
                      checked={values.requiresApproval}
                      disabled={values.obfuscate}
                    />
                    <Flex
                      justify="space-between"
                      align="center"
                      style={{ flex: 1 }}
                    >
                      <label
                        htmlFor="requiresApproval"
                        style={{ cursor: 'pointer' }}
                      >
                        <small>Require approval to join</small>
                      </label>
                      <Tooltip>
                        {values.requiresApproval
                          ? 'You’ll approve Soil Supporters before they can see the soil site location or address.'
                          : 'Anyone can join at any time and see the soil site location or address.'}
                      </Tooltip>
                    </Flex>
                  </Flex>
                </Revealer>

                <FormControlHeader justify="space-between" align="center">
                  <h3>Location:</h3>
                  <Tooltip>
                    Where will you physically locate your soil site? This will
                    only be visible to your soil site participants.
                  </Tooltip>
                </FormControlHeader>

                <AddressField required name="address" />

                <FormControlHeader justify="space-between" align="center">
                  <h3>About soil site:</h3>
                  <Tooltip>
                    Please write a brief description of yourself and your Soil
                    Site.
                  </Tooltip>
                </FormControlHeader>
                <Field
                  as={TextArea}
                  name="description"
                  placeholder="A few words about soil site"
                />

                <ErrorMessage name="description" component={FormInputError} />

                <FormControlHeader justify="space-between" align="center">
                  <h3>Opening Times:</h3>
                  <Tooltip>What times is your soil site accessible?</Tooltip>
                </FormControlHeader>
                <Field
                  as={TextArea}
                  name="hours"
                  placeholder="Anytime during daylight hours"
                />

                <ErrorMessage name="hours" component={FormInputError} />

                <FormControlHeader justify="space-between" align="center">
                  <h3>Access Information:</h3>
                  <Tooltip>
                    How will Soil Supporters find your soil site once they
                    arrive?
                  </Tooltip>
                </FormControlHeader>
                <Field
                  as={TextArea}
                  name="access"
                  placeholder="Write your instructions here"
                />

                <ErrorMessage name="access" component={FormInputError} />

                <FormControlHeader justify="space-between" align="center">
                  <h3>Drop-off Instructions:</h3>
                  <Tooltip>
                    What guidance can you give them on how best to deposit their
                    scraps?
                  </Tooltip>
                </FormControlHeader>
                <Field
                  as={TextArea}
                  name="dropOff"
                  placeholder="Write your drop-off instructions here."
                />

                <ErrorMessage name="dropOff" component={FormInputError} />

                <MaterialsPicker
                  style={{ marginTop: '1rem' }}
                  accepted={values.accepted}
                  prohibited={values.prohibited}
                  acceptedHeader="Accepts"
                  prohibitedHeader="Does NOT Accept"
                  showResetToDefaults={false}
                  onMaterialsChange={(materials) => {
                    setFieldValue('accepted', materials.accepted);
                    setFieldValue('prohibited', materials.prohibited);
                  }}
                />
                <FormButtons
                  onCancel={onCancelEditMode}
                  isSubmitting={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
        {permissions.canDeactivate && (
          <>
            <hr />
            <Flex
              align="center"
              justify="flex-end"
              style={{ paddingBottom: '1rem' }}
            >
              <small
                onClick={() => onDeactivateSite()}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                <FontAwesomeIcon icon="ban" /> Deactivate soil site
              </small>
            </Flex>
          </>
        )}
      </FormContainer>
    </SiteEditContainer>
  );
};

//#region styled components

const SiteEditContainer = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: auto;

  padding: 1rem 1rem 0 1rem;

  border-radius: 0 0 4px 4px;
  overflow-y: auto;
`;

const FormContainer = styled.div`
  h3,
  h4 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  textarea {
    min-height: 5rem;
    resize: vertical;
  }
`;

const FormControlHeader = styled(Flex)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

//#endregion

const FormButtons = ({ onCancel, isSubmitting }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 0',
      }}
    >
      <Button type="button" variant="outlined" onClick={onCancel}>
        Cancel
      </Button>

      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <span>
            Saving... <FontAwesomeIcon icon="spinner" spin />
          </span>
        ) : (
          <span>Save Changes</span>
        )}
      </Button>
    </div>
  );
};

const Sticky = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 12;
`;
