import React from 'react';
import { PickerOverlay } from 'filestack-react';
import { toast } from 'react-toastify';

import { credentials } from '../../../utils/config';
import {
  FilePickerOptions,
  FilestackUploadResponse,
  PickerFileMetadata,
} from '../../../components/ui/FilePicker';

type Props = {
  onSuccess: (response: FilestackUploadResponse) => void;
  onClose: () => void;
  onError?: (err: any) => void;
  onFileSelected?: (file: PickerFileMetadata) => void;
  options?: FilePickerOptions;
  open?: boolean;
};

export const MessageAttachmentPicker: React.FC<Props> = ({
  children,
  options,
  onFileSelected,
  onSuccess,
  onError,
  onClose,
  open = false,
}) => {
  const defaultOptions = {
    accept: ['image/*', 'image/heic'],
    maxFiles: 10,
    maxSize: 5 * 1024 * 1024,
    imageDim: [1920, null],
    transformations: {
      crop: {
        aspectRatio: 4 / 3,
      },
      rotate: true,
      circle: false,
    },
    onClose,
    acceptFn: (file: any, options: any) => {
      const videoMimeTypes = [
        'video/x-flv',
        'video/mp4',
        'application/x-mpegURL',
        'video/3gpp',
        'video/MP2T',
        'video/quicktime',
        'video/x-msvideo',
        'video/x-ms-wmv',
      ];
      const disallowedMimeTypes = [
        'image/svg+xml',
        'application/xml',
        ...videoMimeTypes,
      ];
      return options.mimeFromMagicBytes(file.originalFile).then((mimeType) => {
        // const mime = options.mimeFromExtension(file.originalFile.name);
        if (disallowedMimeTypes.includes(mimeType)) {
          return Promise.reject('File type not accepted.');
        }
        return Promise.resolve();
      });
    },
  };

  const pickerOptions = Object.assign(defaultOptions, options);

  return (
    <>
      {open && (
        <PickerOverlay
          apikey={credentials.get('FILESTACK')}
          pickerOptions={pickerOptions}
          onFileSelected={onFileSelected}
          onError={(err) => (onError ? onError(err) : toast.error(err))}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
