import {
  createAsyncThunk,
  createSlice,
  isPending,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  Attachment,
  NewPostPayload,
  PostType,
  SitePost,
  UpdatePostPayload,
} from './model';

import messageBoardService from './messageBoardService';
import { RootState } from '../../../app/store';
import {
  getRemoveFileUrls,
  removeFilesFromFilestack,
} from '../../filestack/utils';

export type PostAttachment = {
  postId?: number;
} & Attachment;

type MessageBoardSliceState = {
  posts: SitePost[];
  uploadedAttachments: PostAttachment[];
  loading: boolean;
};

const initialState: MessageBoardSliceState = {
  posts: [],
  uploadedAttachments: [],

  loading: false,
};

export const messageBoardSlice = createSlice({
  name: 'messageBoard',
  initialState,
  reducers: {
    clearPosts(state) {
      state.posts = [];
    },
    // incoming real time post
    newPost(state, { payload }: PayloadAction<SitePost>) {
      const existingPost = state.posts.find((p) => p.id === payload.id);
      if (!existingPost) {
        state.posts.unshift(payload);
      }
    },
    attachmentsUploaded(state, { payload }: PayloadAction<PostAttachment[]>) {
      state.uploadedAttachments = [...state.uploadedAttachments, ...payload];
    },
    postUpdated(state, { payload }: PayloadAction<SitePost>) {
      let updatedPost = state.posts.find((p) => p.id === payload.id);
      if (!updatedPost) return;

      updatedPost.text = payload.text;
      updatedPost.pinned = payload.pinned;
      updatedPost.attachments = payload.attachments;

      if (updatedPost.type === PostType.DROPOFF) {
        updatedPost.dropOff.weight = payload.dropOff.weight;
      }
    },
    postDeleted(state, { payload }: PayloadAction<number>) {
      state.posts = state.posts.filter((p) => p.id !== payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPosts.fulfilled, (state, { payload }) => {
        state.posts = payload;
        state.loading = false;
      })
      .addCase(fetchMorePosts.fulfilled, (state, { payload }) => {
        state.posts = [...state.posts, ...payload];
      })
      .addCase(addPost.fulfilled, (state, { payload }) => {
        state.posts = [payload, ...state.posts];

        // filter out attachments that have no postId
        // "new message" has not postId yet
        state.uploadedAttachments = state.uploadedAttachments.filter(
          (a) => a.postId
        );
      })
      .addCase(updatePost.fulfilled, (state, { payload }) => {
        let updatedPost = state.posts.find((p) => p.id === payload.id);
        if (updatedPost) {
          updatedPost.text = payload.text;
          updatedPost.pinned = payload.pinned;
          updatedPost.attachments = payload.attachments;
        }
        // remove tracked uploaded attachments
        state.uploadedAttachments = state.uploadedAttachments.filter(
          (a) => a.postId !== payload.id
        );
      })
      .addCase(deletePost.fulfilled, (state, { payload }) => {
        state.posts = state.posts.filter((p) => p.id !== payload);
      })
      .addCase(removeAttachment.fulfilled, (state, { payload }) => {
        if (payload.postId) {
          const post = state.posts.find((p) => p.id === payload.postId);
          post.attachments.filter((a) => a.handle !== payload.handle);
          // remove the attachment from the uploaded attachments store
          state.uploadedAttachments = state.uploadedAttachments.filter(
            (a) => a.handle !== payload.handle
          );
        }
      })
      .addCase(removeUnusedAttachments.fulfilled, (state, { payload }) => {
        if (payload) {
          state.uploadedAttachments = [];
        }
      })

      .addMatcher(isPending(loadPosts), (state) => {
        state.loading = true;
      });
  },
});

export const {
  clearPosts,
  newPost,
  attachmentsUploaded,
  postUpdated,
  postDeleted,
} = messageBoardSlice.actions;

export const selectPosts = (state: RootState) => state.site.messageBoard.posts;
export const selectIsLoading = (state: RootState) =>
  state.site.messageBoard.loading;

export default messageBoardSlice.reducer;

//
// thunks

export const getMessageBoardObservable = (siteId: number, userId: number) => {
  return messageBoardService.subscribe(siteId, userId);
};

export const loadPosts = createAsyncThunk<SitePost[], number>(
  'messageBoard/loadPosts',
  async (siteId, { rejectWithValue }) => {
    const { data, errors } = await messageBoardService.fetchPosts(siteId);
    if (!data && errors) return rejectWithValue(errors);

    return data.sitePosts;
  }
);

export const fetchMorePosts = createAsyncThunk<
  SitePost[],
  { siteId: number; cursor: Date }
>(
  'messageBoard/fetchMorePosts',
  async ({ siteId, cursor }, { rejectWithValue }) => {
    const { data, errors } = await messageBoardService.fetchPosts(
      siteId,
      cursor
    );
    if (!data && errors) return rejectWithValue(errors);

    return data.sitePosts;
  }
);

export const addPost = createAsyncThunk<
  SitePost,
  NewPostPayload & { siteId: number }
>('messageBoard/addSitePost', async (payload, { rejectWithValue }) => {
  const { data, errors } = await messageBoardService.addPost(
    payload.siteId,
    payload
  );
  if (!data && errors) return rejectWithValue(errors);

  return data.addSitePost;
});

export const updatePost = createAsyncThunk<
  SitePost,
  UpdatePostPayload & { siteId: number }
>('messageBoard/updatePost', async (payload, { rejectWithValue }) => {
  const post = {
    siteId: payload.siteId,
    id: payload.id,
    text: payload.text,
    pinned: payload.pinned,
    attachments: payload.attachments,
    type: payload.type,
  };

  const { data, errors } = await messageBoardService.updatePost(post);
  if (!data && errors) return rejectWithValue(errors);

  return data.updateSitePost;
});

export const deletePost = createAsyncThunk<number, number>(
  'messageBoard/deletePost',
  async (postId, { rejectWithValue }) => {
    const { data, errors } = await messageBoardService.deletePost(postId);
    if (!data && errors) return rejectWithValue(errors);

    if (data.deleteSitePost.deleteFileUrls) {
      const deleteUrls = data.deleteSitePost.deleteFileUrls;
      removeFilesFromFilestack(deleteUrls);
    }

    return postId;
  }
);

export type RemoveAttachment = {
  postId?: number;
  handle: string;
};

export const removeAttachment = createAsyncThunk<
  RemoveAttachment,
  RemoveAttachment
>(
  'messageBoard/removeAttachment ',
  async ({ postId, handle }, { rejectWithValue }) => {
    let deleteUrls = [];

    // if there's a postId this is a post that's also in DB
    if (postId) {
      // delete file from database and get filestack delete url
      const { data, errors } = await messageBoardService.removeAttachment(
        postId,
        handle
      );
      if (!data && errors) return rejectWithValue(errors);

      deleteUrls.push(data.removePostAttachment.url);
    } else {
      // this is a new message (not in DB yet)
      // get generated "delete url" from the server
      const { data, errors } = await getRemoveFileUrls([handle]);
      if (!data && errors) return rejectWithValue(errors);

      deleteUrls = data.getRemoveFileUrls.urls;
    }

    // now delete the file from filestack
    removeFilesFromFilestack(deleteUrls);

    return { postId, handle };
  }
);

export const removeUnusedAttachments = createAsyncThunk<
  boolean,
  void,
  { state: RootState }
>(
  'messageBoard/removeUnusedAttachments ',
  async (_, { rejectWithValue, getState }) => {
    let deleteUrls = [];

    // get
    const unusedAttachments = getState().site.messageBoard.uploadedAttachments;
    if (!unusedAttachments.length) {
      return false;
    }

    // get generated "delete url" from the server
    const { data, errors } = await getRemoveFileUrls(
      unusedAttachments.map((a) => a.handle)
    );
    if (!data && errors) return rejectWithValue(errors);

    deleteUrls = data.getRemoveFileUrls.urls;

    // now delete files from filestack
    removeFilesFromFilestack(deleteUrls);

    return true;
  }
);
