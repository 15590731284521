import { apolloClient } from '../../../app/apolloClient';
import { NewPostPayload, SitePost, UpdatePostPayload } from './model';
import {
  ADD_POST,
  DELETE_POST_ATTACHMENT,
  DELETE_SITE_POST,
  GET_SITE_POSTS,
  POST_SUBSCRIPTION,
  UPDATE_SITE_POST,
} from './graphql';

type FetchPostsResult = {
  sitePosts: SitePost[];
};

type AddPostResult = {
  addSitePost: SitePost;
};

type UpdatePostResult = {
  updateSitePost: SitePost;
};

type DeletePostResult = {
  deleteSitePost: {
    success?: boolean;
    deleteFileUrls?: string[];
  };
};

type RemoveAttachmentResult = {
  removePostAttachment: { url: string };
};

type SubscriptionData = {
  sitePost: {
    action: 'new' | 'update' | 'delete';
    sitePost: SitePost;
  };
};

class MessageBoardService {
  subscribe(siteId: number, userId: number) {
    return apolloClient.subscribe<SubscriptionData>({
      query: POST_SUBSCRIPTION,
      variables: { siteId, userId },
    });
  }

  fetchPosts(siteId: number, cursor: Date = null) {
    return apolloClient.query<FetchPostsResult>({
      query: GET_SITE_POSTS,
      variables: { siteId, cursor },
      fetchPolicy: 'network-only',
    });
  }

  addPost(siteId: number, post: NewPostPayload) {
    return apolloClient.mutate<AddPostResult>({
      mutation: ADD_POST,
      variables: {
        post: {
          siteId,
          ...post,
        },
      },
    });
  }

  updatePost(post: UpdatePostPayload & { siteId: number }) {
    return apolloClient.mutate<UpdatePostResult>({
      mutation: UPDATE_SITE_POST,
      variables: {
        post,
      },
    });
  }

  deletePost(postId: number) {
    return apolloClient.mutate<DeletePostResult>({
      mutation: DELETE_SITE_POST,
      variables: {
        postId,
      },
    });
  }

  removeAttachment(postId: number, handle: string) {
    return apolloClient.mutate<RemoveAttachmentResult>({
      mutation: DELETE_POST_ATTACHMENT,
      variables: {
        postId,
        handle,
      },
    });
  }
}

export default new MessageBoardService();
