import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Logo, BrandContainer } from './Logo';
import { Hamburger } from './Hamburger';
import { Avatar } from './Avatar';
import { DropMenu } from './DropMenu';
import { Button, Flex } from '.';
import { LanguageSelector } from './LanguageSelector';
import { NotificationCentre } from '../../features/notifications/components/NotificationCentre';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUnreadMessagesCount } from '../../features/messenger/messengerSlice';
import { generateUserIdentifier } from '../../utils/url';
import { logout } from '../../features/auth/authSlice';
import {
  navigationToggled,
  selectNavigationOpen,
  selectScreenSize,
} from '../../features/layout/layoutSlice';
import { subscriptionClient } from '../../app/apolloClient';
import { useAuthUser } from '../../features/auth/useAuthUser';
import { useSidenavToggle } from '../../hooks/useSideNavToggle';

type HeaderProps = {
  extraNav?: React.ReactNode;
  showLanguageSelector?: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const { extraNav, showLanguageSelector = false } = props;

  const history = useHistory();

  const dispatch = useAppDispatch();
  const authUser = useAuthUser();
  const screenSize = useAppSelector(selectScreenSize);
  const navigationOpen = useAppSelector(selectNavigationOpen);
  const unreadMessagesCount = useAppSelector(selectUnreadMessagesCount);

  const { toggleSideNav } = useSidenavToggle();

  const handleLogout = async () => {
    await dispatch(logout());
    // unsubscribe and close socket connection
    subscriptionClient.unsubscribeAll();
    subscriptionClient.close();
    history.replace('/');
  };

  const handleHeaderClick = () => {
    if (screenSize === 'sm' && navigationOpen) {
      dispatch(navigationToggled({ userTriggered: false }));
    }
  };

  return (
    <HeaderContainer className="header" onClick={() => handleHeaderClick()}>
      <BrandContainer hideLogoType={!navigationOpen}>
        <Link to="/" style={{ display: 'flex' }}>
          <Logo hideLogoType={!navigationOpen} />
        </Link>
      </BrandContainer>

      <Hamburger toggle={() => toggleSideNav(true)} />

      <HeaderNav>
        <ExtraNav>
          {/* <Tooltip
            trigger={'mouseenter focus'}
            triggerElement={
              <Link to="/donate" style={{ marginRight: '1rem' }}>
                <Button circle>
                  <FontAwesomeIcon icon="donate" />
                </Button>
              </Link>
            }
          >
            Donate to MakeSoil
          </Tooltip> */}

          {extraNav}

          {showLanguageSelector && <LanguageSelector />}
        </ExtraNav>

        <UserNav>
          {authUser.isLoggedIn ? (
            <UserSection>
              <MessagesToggle
                onClick={() => {
                  history.push(`/messages`);
                }}
              >
                <FontAwesomeIcon icon="envelope" />
                {unreadMessagesCount > 0 && (
                  <CountIndicator>{unreadMessagesCount}</CountIndicator>
                )}
              </MessagesToggle>
              <NotificationCentre />
              <DropMenu
                triggerElement={
                  <Avatar size="sm" user={authUser} profileLink={false} round />
                }
              >
                <span
                  onClick={() => {
                    history.push(
                      `/user/${generateUserIdentifier(authUser.id)}`
                    );
                  }}
                >
                  <FontAwesomeIcon icon="user-edit" /> Personal Info
                </span>
                <span
                  onClick={() => {
                    history.push(`/account`);
                  }}
                >
                  <FontAwesomeIcon icon="cogs" /> Account Settings
                </span>
                <span onClick={handleLogout}>
                  <FontAwesomeIcon icon="sign-out-alt" /> Sign out
                </span>
              </DropMenu>
            </UserSection>
          ) : (
            <LoginSection>
              <div className="buttons">
                <Button
                  onClick={() => history.push('/login')}
                  variant="outlined"
                  className="login-btn"
                >
                  Log in
                </Button>
                <Button onClick={() => history.push('/signup')}>Sign up</Button>
              </div>
              <div className="dropdown">
                <DropMenu triggerElement={<Button>Sign up / in</Button>}>
                  <span
                    onClick={() => {
                      history.push(`/signup`);
                    }}
                  >
                    <FontAwesomeIcon icon="user-plus" /> Sign Up
                  </span>
                  <span
                    onClick={() => {
                      history.push(`/login`);
                    }}
                  >
                    <FontAwesomeIcon icon="sign-in-alt" /> Sign In
                  </span>
                </DropMenu>
              </div>
            </LoginSection>
          )}
        </UserNav>
      </HeaderNav>
    </HeaderContainer>
  );
};

//#region styled components

const HeaderContainer = styled.div`
  grid-area: header;
  background-color: ${(props) => props.theme.colors.background};
  border-bottom: 1px solid #333;

  display: grid;
  grid-template-columns: auto auto 1fr;
  padding-top: env(safe-area-inset-top);
`;

const HeaderButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 38px;
  width: 38px;
  margin-right: 0.5rem;

  cursor: pointer;

  background-color: #333;
  border-radius: 50%;

  :hover {
    background-color: #3c3c3c;
  }

  transition 0.35s ease;
`;

const MessagesToggle = styled(HeaderButton)`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none !important;
  }
`;

const CountIndicator = styled.span`
  position: absolute;
  top: -3px;
  right: -3px;

  padding: 0 0.275rem;
  border-radius: 0.5rem;

  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  color: #fff;
  pointer-events: none;

  transition: all ${({ theme }) => theme.timing}s ease;
`;

const HeaderNav = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    order: 3;
  }

  nav {
    padding: 0 1rem;

    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      display: none;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 1rem 0.5rem;

        .fa {
          margin-right: 0.5rem;
        }

        a {
          text-decoration: none;
          color: #d9d9d9;
        }
      }
    }
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ExtraNav = styled(Flex)`
  @media (hover: none) {
    display: none;
  }

  @media (hover: hover) {
    display: flex;
  }
`;

const UserNav = styled.div`
  padding: 0 0.5rem;
`;

const UserSection = styled(Flex)`
  color: #d9d9d9;

  // @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
  //   border-left: 1px solid #333;
  // }

  > div {
    display: flex;
    align-items: center;
    margin: 0 0.5rem;
  }

  .fa {
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

const LoginSection = styled.div`
  .buttons {
    display: none;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      display: block;
    }
  }

  .dropdown {
    display: block;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      display: none;
    }
  }

  button {
    font-weight: 600;
    margin: 0 0.5rem;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    padding: 0 2rem;
    font-weight: 600;
    color: #d9d9d9;
  }

  .login-btn {
    color: #d9d9d9 !important;
    border-color: #d9d9d9 !important;

    :hover {
      color: #fff !important;
      border-color: #fff !important;
    }
  }
`;

//#endregion
