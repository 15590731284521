import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Modal, Button, Flex } from '../../../components/ui';
import { useSubmitting } from '../../../hooks/useSubmitting';
import { useSoilSiteContext } from '../SoilSiteContext';
import { useAuthUser } from '../../auth/useAuthUser';
import { DropOffSuccess } from './DropOffSuccess';
import { useDropOff } from './useDropOff';
import { WeightUnit, getWeightUnit } from '../../../utils/formatting';

import { DropOffForm } from './DropOffForm';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { isMobile } from '../../../utils/helpers';
import { isMobileApp } from '../../../mobile/mobile';

type SuccessMessage = 'first-drop-off' | 'x-drop-off';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const RegisterDropOffModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const history = useHistory();
  const authUser = useAuthUser();
  const {
    siteIdentifier,
    soilSite,
    currentParticipant,
    isSupporter,
    isMaker,
  } = useSoilSiteContext();

  const { registerDropOff, convertWeightBeforeSaving } = useDropOff();

  const [dropOffAuthorId, setDropOffAuthorId] = useState<number>(authUser.id);
  const [comment, setComment] = useState('');
  const [weight, setWeight] = useState('');
  const [weightUnit, setWeightUnit] = useLocalStorage<{ unit: WeightUnit }>(
    'weight-unit',
    getWeightUnit()
  );

  const [successMessage, setSuccessMessage] = useState<SuccessMessage>();

  const [showConfirmDropOff, setShowConfirmDropOff] = useState(
    isSupporter &&
      !!currentParticipant.dropOffs.find((d) => isWithinADay(d.createdAt))
  );

  const myDropOffCount = currentParticipant.dropOffs.length;

  const handleRegisterDropOff = async () => {
    try {
      await registerDropOff({
        comment,
        weight: convertWeightBeforeSaving(weight, weightUnit.unit),
        siteId: soilSite.id,
        dropAsUserId: dropOffAuthorId !== authUser.id ? dropOffAuthorId : null,
      });

      if (isMobile() || isMobileApp()) {
        // push to activity tab
        history.push(`/my-soil-sites/${siteIdentifier}?tab=activity`);
      }

      const isFirstDropOff = myDropOffCount === 0;
      if (isFirstDropOff) {
        setSuccessMessage('first-drop-off');
      } else {
        toast.success('Congratulations! You have recorded a drop-off.');
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleParticipantSelected = (userId: number) => {
    setDropOffAuthorId(userId);
  };

  const { handleSubmit, isSubmitting } = useSubmitting(handleRegisterDropOff);

  const showSuccess = successMessage === 'first-drop-off';

  function renderActionButton() {
    return showSuccess ? (
      <Button onClick={onClose}>CONTINUE</Button>
    ) : (
      <Button
        onClick={handleSubmit}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        RECORD DROP-OFF
      </Button>
    );
  }

  const modalHeader = showSuccess
    ? undefined
    : showConfirmDropOff
    ? 'Is this a new drop-off?'
    : `Record drop-off at ${soilSite.name}`;

  return (
    <Modal
      header={modalHeader}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={renderActionButton()}
      cancelButtonText={showSuccess ? 'Close' : undefined}
      hideFooter={showConfirmDropOff}
    >
      {showSuccess ? (
        <DropOffSuccess />
      ) : showConfirmDropOff ? (
        <ConfirmDropOff
          onClose={onClose}
          setConfirmDropOff={setShowConfirmDropOff}
        />
      ) : (
        <DropOffForm
          useDropOffAs={isMaker}
          useWeight={true}
          weight={weight}
          setWeight={setWeight}
          weightUnit={{ unit: weightUnit?.unit }}
          setWeightUnit={setWeightUnit}
          comment={comment}
          setComment={setComment}
          selectedParticipantUserId={dropOffAuthorId}
          onParticipantSelected={handleParticipantSelected}
        />
      )}
    </Modal>
  );
};

function ConfirmDropOff({ setConfirmDropOff, onClose }) {
  return (
    <>
      <p>A drop-off was already recorded for you in the last 24 hours.</p>
      <p>Are you recording a new drop-off, or is this a duplicate?</p>
      <Flex direction="column" gap="1rem">
        <Button variant="danger" onClick={() => onClose()}>
          IT'S A DUPLICATE, CANCEL
        </Button>
        <Button onClick={() => setConfirmDropOff(false)}>
          RECORD NEW DROP-OFF
        </Button>
      </Flex>
    </>
  );
}

function isWithinADay(date: Date) {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date.getTime() > Date.now() - 3600 * 24 * 1000;
}
