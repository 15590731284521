import { useCallback } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { newPost, postUpdated } from '../messageboard/messageBoardSlice';
import { gql, useMutation } from '@apollo/client';
import { DropOffPayload, EditDropOffPayload } from './model';
import { WeightUnit } from '../../../utils/formatting';
import { kgToLbs, lbsToKg } from '../../../utils/weightConverter';
import { GET_SOIL_SITE } from '../graphql';

const DROP_OFF = gql`
  mutation DropOff($dropOff: DropOffInput!) {
    registerDropOff(dropOff: $dropOff) {
      id
      text
      pinned
      attachments
      createdAt
      siteId
      type
      author {
        id
        firstName
        lastName
        picture
      }
      dropOff {
        weight
      }
    }
  }
`;

const UPDATE_DROP_OFF = gql`
  mutation UpdateDropOff($dropOff: UpdateDropOffInput!) {
    updateDropOff(dropOff: $dropOff) {
      id
      text
      pinned
      attachments
      createdAt
      siteId
      type
      author {
        id
        firstName
        lastName
        picture
      }
      dropOff {
        weight
      }
    }
  }
`;

export function useDropOff() {
  const dispatch = useAppDispatch();
  const [registerDrop] = useMutation(DROP_OFF);
  const [updateDrop] = useMutation(UPDATE_DROP_OFF);

  const registerDropOff = useCallback(
    async (dropOff: DropOffPayload) => {
      const { data } = await registerDrop({
        variables: { dropOff },
        refetchQueries: [GET_SOIL_SITE],
      });
      // add the post (drop) to the activity board UI
      dispatch(newPost(data.registerDropOff));
    },
    [dispatch, registerDrop]
  );

  const updateDropOff = useCallback(
    async (dropOff: EditDropOffPayload) => {
      const { data } = await updateDrop({
        variables: { dropOff },
        refetchQueries: [GET_SOIL_SITE],
      });

      // update the post (drop) on the activity board UI
      dispatch(postUpdated(data.updateDropOff));
    },
    [dispatch, updateDrop]
  );

  /**
   * Convert weight to the weight we store in if needed
   * We store in pounds
   */
  const convertWeightBeforeSaving = useCallback(
    (weight: string, weightUnit: WeightUnit) => {
      let dropWeight = weight;
      // account for a , and replace with . ex => ,9 | 0,9
      if (weight.includes(',')) {
        dropWeight = weight.replace(',', '.');
      }
      // account for a leading 0 and no . ex => 09
      if (dropWeight.startsWith('0') && dropWeight.indexOf('.') === -1) {
        dropWeight = [dropWeight.slice(0, 1), '.', dropWeight.slice(1)].join(
          ''
        );
      }
      // account for the lack of leading 0 ex => .9
      if (dropWeight.startsWith('.')) {
        dropWeight = `0${dropWeight}`;
      }

      const parsedWeight = parseFloat(dropWeight);
      const returnWeight =
        weightUnit === 'kilogram' ? kgToLbs(parsedWeight) : parsedWeight;

      return returnWeight;
    },
    []
  );

  /**
   * convert weight to the chosen display weight
   */
  const convertWeightToDisplay = useCallback(
    (weight: number, displayWeightUnit: WeightUnit) => {
      return displayWeightUnit === 'kilogram' ? lbsToKg(weight) : weight;
    },
    []
  );

  return {
    registerDropOff,
    updateDropOff,

    convertWeightBeforeSaving,
    convertWeightToDisplay,
  };
}
