import { gql } from '@apollo/client';

export const CREATE_SOIL_SITE = gql`
  mutation(
    $name: String!
    $address: AddressInput!
    $location: LocationInput!
    $picture: String
    $description: String
    $access: String
    $dropOff: String
    $hours: String
    $accepted: [String!]!
    $prohibited: [String!]!
    $requiresApproval: Boolean!
    $obfuscate: Boolean!
    $isUnlisted: Boolean!
    $firstName: String
    $lastName: String
    $phoneNum: String
    $twitter: String
    $instagram: String
    $linkedin: String
    $facebook: String
    $saveAddress: Boolean
  ) {
    createSite(
      site: {
        name: $name
        address: $address
        location: $location
        picture: $picture
        description: $description
        access: $access
        dropOff: $dropOff
        hours: $hours
        accepted: $accepted
        prohibited: $prohibited
        requiresApproval: $requiresApproval
        obfuscate: $obfuscate
        isUnlisted: $isUnlisted
        firstName: $firstName
        lastName: $lastName
        phoneNum: $phoneNum
        twitter: $twitter
        instagram: $instagram
        linkedin: $linkedin
        facebook: $facebook
        saveAddress: $saveAddress
      }
    ) {
      id
      name
      description
    }
  }
`;
