import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  PostAttachment,
  RemoveAttachment,
  addPost,
  attachmentsUploaded,
  clearPosts,
  deletePost,
  fetchMorePosts,
  loadPosts,
  removeAttachment,
  removeUnusedAttachments,
  selectIsLoading,
  selectPosts,
  updatePost,
} from './messageBoardSlice';
import { PostInputData, SitePost } from './model';

export function useMessageBoard() {
  const dispatch = useAppDispatch();

  const posts = useAppSelector(selectPosts);
  const loading = useAppSelector(selectIsLoading);

  const fetchPosts = useCallback(
    (siteId: number) => dispatch(loadPosts(siteId)),
    [dispatch]
  );

  const fetchMore = useCallback(
    ({ siteId, cursor }: { siteId: number; cursor: Date }) =>
      dispatch(fetchMorePosts({ siteId, cursor })),
    [dispatch]
  );

  const addSitePost = useCallback(
    ({ postData, siteId }: { postData: PostInputData; siteId: number }) =>
      dispatch(addPost({ ...postData, siteId })),
    [dispatch]
  );

  const updateSitePost = useCallback(
    (post: SitePost) => dispatch(updatePost(post)),
    [dispatch]
  );

  const deleteSitePost = useCallback(
    (postId: number) => dispatch(deletePost(postId)),
    [dispatch]
  );

  const deleteAttachment = useCallback(
    (action: RemoveAttachment) => dispatch(removeAttachment(action)),
    [dispatch]
  );

  const storeUploadedAttachments = useCallback(
    (attachments: PostAttachment[]) =>
      dispatch(attachmentsUploaded([...attachments])),
    [dispatch]
  );

  const clearUnusedAttachments = useCallback(
    () => dispatch(removeUnusedAttachments()),
    [dispatch]
  );

  const cleanupMessageBoard = useCallback(() => {
    dispatch(clearPosts());
    dispatch(removeUnusedAttachments());
  }, [dispatch]);

  return {
    posts,
    loading,
    fetchPosts,
    fetchMore,
    addSitePost,
    updateSitePost,
    deleteSitePost,
    deleteAttachment,
    storeUploadedAttachments,
    clearUnusedAttachments,
    cleanupMessageBoard,
  };
}
