import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import { Meta } from '../../components/Meta';
import { TabLayout } from './SiteTabLayout';
import { SiteViewHeader } from './SiteViewHeader';
import { SiteEdit } from './info/SiteEdit';
import { SiteInfo } from './info/SiteInfo';
import { MessageBoard } from './messageboard/MessageBoard';
import { Participation } from './participation/Participation';
import {
  useCurrentSiteUser,
  useAutoOpenModal,
  useEditMode,
  useSoilSiteContext,
  useAdaptiveLayout,
} from './hooks';
import { GridLayout } from './GridLayout';
import { SiteImpact } from './impact/Impact';

export const SoilSiteView = () => {
  const { siteIdentifier, soilSite } = useSoilSiteContext();
  const { editMode, enterEditMode, cancelEditMode } = useEditMode();
  const { modal, openModal } = useAutoOpenModal(soilSite);
  const { canAccessSite, permissions } = useCurrentSiteUser(soilSite);

  const siteView = useAdaptiveLayout();

  if (!canAccessSite) return <Redirect to={`/map/site/${siteIdentifier}`} />;

  const siteInfoEditComponent =
    permissions.canEdit && editMode ? (
      <SiteEdit
        onCancelEditMode={cancelEditMode}
        onDeactivateSite={openModal.deactivateSite}
      />
    ) : (
      <SiteInfo
        onGetDirections={() =>
          openModal.getDirections(soilSite.location, soilSite.name)
        }
        onEdit={enterEditMode}
        onLeaveSite={openModal.leaveSite}
      />
    );

  const messageBoardComponent = (
    <MessageBoard
      onRegisterDropOff={openModal.registerDropOff}
      onAddSupporters={() => openModal.invite('qr')}
      onEditDropOff={(post) =>
        openModal.editDropOff({
          weight: post.dropOff.weight,
          siteId: post.siteId,
          comment: post.text,
          sitePostId: post.id,
        })
      }
    />
  );

  const participationComponent = (
    <Participation
      onGetMoreSupporters={() => openModal.invite('qr')}
      onTransferOwnership={openModal.transferOwnership}
      onPromoteToSoilMaker={openModal.promoteToSoilMaker}
      onDemoteSoilMaker={openModal.demoteMember}
      onRemoveMember={openModal.removeMember}
      onReviewAccessRequest={openModal.reviewAccessRequest}
    />
  );

  return (
    <>
      <Meta>
        <title>{soilSite.name} | MakeSoil</title>
      </Meta>
      {modal}
      <SiteViewHeader
        onAddSupporters={() => openModal.invite()}
        onRegisterDropOff={openModal.registerDropOff}
      />
      <Container>
        {siteView === 'tab' ? (
          <TabLayout
            siteInfoEditComponent={siteInfoEditComponent}
            participationComponent={participationComponent}
            messageBoardComponent={messageBoardComponent}
            siteImpactComponent={
              <SiteImpact
                onAddSupporters={() => openModal.invite()}
                onRegisterDropOff={openModal.registerDropOff}
              />
            }
          />
        ) : (
          <GridLayout
            siteInfoEditComponent={siteInfoEditComponent}
            participationComponent={participationComponent}
            messageBoardComponent={messageBoardComponent}
          />
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  height: 100%;
  overflow: hidden;
`;
